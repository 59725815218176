import { Link, Routes, Route, useNavigate } from "react-router-dom";

import { auth, logout } from "./firebase";
import HomePage from "./pages/Home";
import Dashboard from "./Dashboard";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import SettingsPage from "./pages/Settings";
import Contacts from "./pages/Contacts";
import Signup from "./pages/Signup";
import "./App.css";

export default function App() {
  const sideNavigation = false;

  return (
    <div className="w-full">
      <Nav />
      <div className="flex w-full">
        {sideNavigation && (
          <div className="bg-slate-400 p-4 text-white">
            <ul>
              <li>Home</li>
              <li>Docs</li>
              <li>Tasks</li>
              <li>Projects</li>
              <li>Contacts</li>
              <li>Status</li>
              <li>Reports</li>
              <li>Label</li>
            </ul>
          </div>
        )}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </div>
    </div>
  );
}

function Nav() {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <nav>
      <ul>
        <li>
          <Link to="/">
            <span className="font-bold">FORPEPO</span>
          </Link>
        </li>
        {auth.currentUser && (
          <>
            <li>
              <Link to="/settings">Settings</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/contacts">Contacts</Link>
            </li>
          </>
        )}
      </ul>
      <div className="text-right mr-4">
        {auth.currentUser ? (
          <button onClick={() => handleLogout()}>Logout</button>
        ) : (
          <button onClick={() => navigate("/login")}>Login</button>
        )}
      </div>
    </nav>
  );
}
