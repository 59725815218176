import { auth, logInWithEmailAndPassword } from "../firebase";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) return navigate("/dashboard");
  }, [user, loading, navigate]);

  return (
    <div className="flex justify-center">
      <div className="mt-10 w-64">
        <h3 className="font-black">Login</h3>
        <p className="pt-4 pb-4">Hi, please login or signup!</p>
        <label htmlFor="email-address">Email address</label>
        <input
          id="email-address"
          name="email"
          type="email"
          required
          placeholder="Email address"
          onChange={(e) => setEmail(e.target.value)}
          className="p-4"
        />
        <label htmlFor="password">Password</label>
        <input
          id="password"
          name="password"
          type="password"
          required
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="flex justify-center pt-4">
          <button
            className="btn btn-primary mb-8 mt-4"
            onClick={() => logInWithEmailAndPassword(email, password)}
          >
            Login
          </button>
        </div>
        <p className="text-sm text-center">
          <Link to="/forgotpassword">Forgot your password?</Link>
        </p>
        <p className="text-sm text-center">
          No account yet? <Link to="/signup">Sign up</Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
