const HomePage = () => {
  return (
    <div className="p-4">
      <h1 className="mt-10 text-center text-5xl">
        For pepo who connect with pepo.
      </h1>
    </div>
  );
};

export default HomePage;
