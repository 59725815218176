import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import uuid from "react-uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import { auth, db } from "../firebase";
import { redirect, useNavigate } from "react-router-dom";
import { Notepad } from "../components/notepad/notepad";
import { onValue, ref, set } from "firebase/database";

const Contacts = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [contacts, setContacts] = useState<Record<string, any>[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState("");
  const navigate = useNavigate();

  const saveContacts = (payload: Record<string, any>[]) => {
    const uid = auth.currentUser?.uid;
    set(ref(db, `/user_contacts/${uid}`), payload);
  };

  useEffect(() => {
    onValue(ref(db), (snapshot) => {
      const data: Record<string, any> = snapshot.val();
      if (data !== null) {
        auth.currentUser &&
          setContacts(data.user_contacts[auth.currentUser?.uid]);
      }
    });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(contacts) && !_.isNull(contacts)) {
      const updatedContacts = JSON.stringify(contacts);
    }

    if (!_.find(contacts, (c) => c.id === selectedContactId)) {
      return setSelectedContactId("");
    }
  }, [contacts]);

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/");
    }
  }, [auth]);

  const addContact = (newContact: Record<string, any>) => {
    saveContacts([...contacts, newContact]);
  };

  const deleteContact = (id: string) => {
    window.console.log("id here is ", id);
    const filteredContacts = contacts.filter((c: Record<string, any>) => {
      return c.id !== id;
    });
    setContacts(filteredContacts);
    saveContacts(filteredContacts);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    const id = uuid();
    const newContact = { id, firstname, lastname, email };

    resetFields();
    setOpen(false);
    addContact(newContact);
  };

  const resetFields = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
  };

  const deleteAllContacts = () => {
    window.localStorage.setItem("contacts", "");
    setContacts([]);
  };

  return (
    <div className="w-full">
      <div className="m-4">
        <h2 className="text-4xl font-bold">Contacts</h2>
        <div className="mt-4">
          <button
            type="button"
            onClick={handleClickOpen}
            className="rounded-md bg-blue-500 p-2 mb-4 text-white mr-2"
          >
            Add New
          </button>
          <button
            type="button"
            onClick={deleteAllContacts}
            className="rounded-md ml-2 bg-red-500 p-2 mb-4 text-white"
          >
            Delete All Contacts
          </button>
        </div>
      </div>
      <div className="flex p-4">
        <div className="w-48">
          <div>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Add New Person</DialogTitle>
              <div className="p-10">
                <label>Person Details</label>
                <TextField
                  variant="standard"
                  label="First Name"
                  value={firstname}
                  fullWidth
                  onChange={(e) => setFirstname(e.target.value)}
                />
                <TextField
                  variant="standard"
                  label="Last Name"
                  fullWidth
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
                <TextField
                  variant="standard"
                  label="Email"
                  type="email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <DialogActions>
                  <Button onClick={() => handleSubmit()}>Submit</Button>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                </DialogActions>
              </div>
            </Dialog>
            <ul>
              {contacts.map((person: Record<string, any>) => {
                window.console.log("person is ", person);
                return (
                  <li
                    key={person.id}
                    onClick={() => setSelectedContactId(person.id)}
                  >
                    {person.firstname} {person.lastname}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="w-3/4">
          <ContactDetails
            selectedContactId={selectedContactId}
            contacts={contacts}
            deleteContact={(id) => deleteContact(id)}
          />
        </div>
      </div>
    </div>
  );
};

export default Contacts;

interface ContactDetailsProps {
  readonly selectedContactId: string;
  readonly contacts: Record<string, any>[];
  readonly deleteContact: (id: string) => any;
}
function ContactDetails(props: ContactDetailsProps) {
  const { contacts, selectedContactId, deleteContact } = props;
  const [person, setPerson] = useState<Record<string, any>>({});
  const [personEmpty, setPersonEmpty] = useState(true);

  useEffect(() => {
    window.console.log("195 - ccontacts ", contacts);
    if (!_.isEmpty(contacts)) {
      const selectedPerson =
        _.find(contacts, (p) => p.id === selectedContactId) || {};
      setPerson(selectedPerson);
      setPersonEmpty(_.isEmpty(selectedPerson));
    }
  }, [selectedContactId]);

  if (personEmpty) {
    return (
      <div>
        No person is selected. Try clicking a person in the menu to the left or
        create a new person.
      </div>
    );
  }

  return (
    <div className="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-lg rounded-2xl bg-clip-border p-8">
      <h2 className="text-2xl">
        {person.firstname} {person.lastname}
      </h2>
      <div className="m-4">
        <h5 className="text-slate-800 uppercase font-bold text-sm">Email</h5>
        <span>{person.email}</span>
      </div>
      <Notepad person={person} />
      <div className="mt-10">
        <Button onClick={() => deleteContact(person.id)}>
          <DeleteIcon fontSize="small" />
          Delete Contact
        </Button>
      </div>
    </div>
  );
}
