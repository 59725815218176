import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { auth } from "../firebase";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent");
  };

  return (
    <div>
      <div>
        <div className="flex justify-center">
          <div className="mt-10 w-64">
            <h3 className="text-center font-bold text-lg">
              Reset your password
            </h3>
            <p>
              Enter your email address into the field below. You will receive an
              email with instructions to change your password.
            </p>
            <div className="center mt-4">
              <input
                type="text"
                placeholder="Enter your email"
                onChange={(event) => setEmail(event.target.value)}
              />
              <button
                onClick={triggerResetEmail}
                className="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              >
                send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
