import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";

const Dashboard = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) {
      return navigate("/");
    }
  }, [user, loading, navigate]);

  return (
    <div className="layout">
      <p>Logged in as {user?.email}</p>
    </div>
  );
};

export default Dashboard;
