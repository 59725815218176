import { Button } from "@mui/material";
import localforage from "localforage";
import React, {
  MouseEventHandler,
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Form, useFetcher } from "react-router-dom";
import { updateContact } from "../../contacts";
import uuid from "react-uuid";
import { auth } from "../../firebase";
import { User } from "firebase/auth";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";

interface Note {
  readonly id: string;
  readonly contactId: string;
  readonly message: string;
  readonly timestamp: string;
  readonly createdBy: any;
}
const createNote = (message: string, contactId: string, auth: any): Note => {
  const id = uuid();
  const timestamp = new Date().toLocaleString();
  const createdBy = auth.currentUser;

  return { id, contactId, timestamp, message, createdBy };
};

const getStoredNotes = (selectedContactId = ""): Note[] => {
  const valueString = localStorage.getItem("notesCollection");
  const parsedNotesCollection: Note[] = valueString
    ? JSON.parse(valueString)
    : ([] as Note[]);
  // window.console.log("parsedNotesCollection ", parsedNotesCollection);
  if (!parsedNotesCollection) return [] as Note[];

  if (!selectedContactId) return parsedNotesCollection;

  const relevantSliceOfNotes = parsedNotesCollection.filter(
    (pn: Note) => pn.contactId === selectedContactId
  );

  return relevantSliceOfNotes;
};

const Notepad = ({ person }: Record<string, any>) => {
  const [selectedContactId, setSelectedContactId] = useState<string>(
    person.id || ""
  );
  const [localNote, setLocalNote] = useState<string>("");
  const [notes, setNotes] = useState<Note[]>(() => {
    const defaultNotes = [] as Note[];
    const valueString = localStorage.getItem("notesCollection");
    const parsedNotesCollection = valueString && JSON.parse(valueString);
    window.console.log("parsedNotesCollection ", parsedNotesCollection);
    const relevantSliceOfNotes = parsedNotesCollection?.filter(
      (pn: Note) => pn.contactId === selectedContactId
    );
    return relevantSliceOfNotes || defaultNotes;
  });

  const saveNote = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const newNote = createNote(localNote, person.id, auth.currentUser);

    setNotes((prevState: Note[]) => [...prevState, newNote]);
    // window.localStorage.setItem("notesCollection");
    setLocalNote("");
  };

  const removeNote = async (id: string) => {
    window.console.log("notes before removal ", notes);
    window.console.log("id to be removed ", id);
    const removedOneNote = notes.filter((note: Note) => note.id !== id);
    setNotes(removedOneNote);

    const getAllNotes = getStoredNotes();
    const filteredNotes = getAllNotes.filter((n) => n.id !== id);
    const updatedNotesCollection = JSON.stringify(filteredNotes);
    window.localStorage.setItem("notesCollection", updatedNotesCollection);
    window.console.log(
      "note has been removed from local notes usestate",
      removedOneNote
    );
  };

  useLayoutEffect(() => {
    if (selectedContactId !== person.id) {
      // we need to update selected contact id variable
      setSelectedContactId(person.id);
      // and get those notes relevant to this person
      const newNotes = getStoredNotes(person.id);
      window.console.log("new notes -> 83 ", newNotes);
      window.console.log("old notes -> 84 ", notes);
      const isEqual = _.isEqual(newNotes, notes);
      window.console.log("isEqual ", isEqual);
      _.isEqual(newNotes, notes) === false && setNotes(newNotes);
    }
  }, [person.id]);

  useEffect(() => {
    const allNotes = getStoredNotes();
    const updatedNotesCollection = JSON.stringify([...allNotes]);
    window.console.log("current notes 108 ", notes);
    window.console.log("allNotes 109 ", allNotes);
    window.localStorage.setItem("notesCollection", updatedNotesCollection);
  }, [notes]);

  return (
    <div className="max-w-full px-3 mb-4 lg:mb-0 lg:w-full lg:flex-none">
      <div>
        <label>notes:</label>
        {notes.map((note: Note, indx: number) => {
          return (
            <div
              key={uuid()}
              className="relative flex p-6 mb-2 border-0 rounded-t-inherit rounded-xl bg-gray-50"
            >
              <p>
                {note.message}{" "}
                <span className="text-sm block text-right">
                  {note.timestamp}
                </span>
              </p>

              <button className="ml-auto" onClick={() => removeNote(note.id)}>
                <DeleteIcon fontSize="small" />
              </button>
            </div>
          );
        })}
      </div>
      <form onSubmit={saveNote}>
        <div>
          <textarea
            className="border"
            name="notepad"
            id="notepad"
            placeholder="Please type here to begin a new note ..."
            value={localNote}
            onChange={(e) => setLocalNote(e.currentTarget.value)}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          // className="btn"
        >
          Save
        </Button>
        <Button
        //   onClick={() => removeAllNotes()}
        >
          Delete All Notes
        </Button>
      </form>
    </div>
  );
};

export { Notepad };
